<template>
  <div style="height: 100%">
    <div style="width: 100%">
      <el-card class="box-card" style="height: 100%">
        <div style="display: flex">
          <div class="RE">
            <el-button
              type="primary"
              v-if="addButton"
              icon="el-icon-plus"
              @click="handleAdd"
              size="small"
              >新增角色
            </el-button>
          </div>
          <div class="RT">
            <el-button
              type="primary"
              icon="el-icon-edit"
              v-if="updateButton"
              size="small"
              @click="handleUpdate(updateId)"
              >修改</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-delete"
              v-if="deleteButton"
              @click="handleDelete"
              size="small"
              >删除
            </el-button>
          </div>
        </div>
      </el-card>
    </div>

    <div style="display: flex; width: 100%; height: calc(100vh - 225px)">
      <div style="width: 16%; height: 100%">
        <el-card class="box-card" style="height:100%; margin-top: 15px;overflow-y: auto;">
          <div slot="header" class="clearfix">
            <span>员工角色</span>
          </div>
          <div>
            <div v-for="(item, index) in qyroleList" :key="index">
              <div style="margin-top: 20px">
                <label for="" style="margin-top: 12px">{{ item.code }} </label>
              </div>
              <div style="margin-top: 8px">
                <label
                  :class="isplays == index ? 'okSelect' : 'noSelect'"
                  @click="changes(index, item.id)"
                >
                  {{ item.name }}
                </label>
              </div>
            </div>
          </div>
        </el-card>
      </div>

      <div style="width: 84%; height: 100%" class="YY">
        <el-card
          class="box-card"
          style="height: 100%; margin-top: 15px; margin-left: 15px;overflow-y: auto;"
        >
          <span>角色权限设置</span>

          <!-- <el-tree :data="menuTree" show-checkbox node-key="id" :default-expanded-keys="expandedKeys"
            :default-checked-keys="checkedKeys" :props="{ label: 'name' }">
          </el-tree> -->
          <div>
            <el-checkbox-group
              v-model="checked"
              v-for="(item, index) in menuTree"
              :key="index"
            >
              <el-card
                class="box-card TY"
                style="height: 100%; margin-top: 15px"
                shadow="never"
              >
                <div slot="header" class="clearfix" style="margin-top: -33px">
                  <div class="FGs">
                    <el-checkbox
                      style="font-weight: bold"
                      @change="Chess(item.parentIds, item.children, item)"
                      :label="item.id"
                      >{{ item.name }}
                    </el-checkbox>
                  </div>
                </div>
                <div
                  v-for="(item, index1) in menuTree[index].children"
                  :key="index1"
                  class="FGss"
                >
                  <el-checkbox
                    style="
                      margin-left: 26px;
                      font-weight: bold;
                      margin-top: -15px !important;
                    "
                    :label="item.id"
                    @change="Chess(item.parentIds, item.children, item)"
                  >
                    {{ item.name }}
                  </el-checkbox>

                  <div class="FGsss">
                    <div
                      v-for="(item, index2) in menuTree[index].children[index1]
                        .children"
                      :key="index2"
                    >
                      <el-checkbox
                        :label="item.id"
                        style="margin-left: 20px"
                        @change="Chess(item.parentIds, [], item)"
                      >
                        {{ item.name }}
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </el-card>
            </el-checkbox-group>
          </div>

          <div
            style="width：100% ; margin-top: 20px;
    display: flex;
    justify-content: flex-end;"
          >
            <el-button
              class="bnts"
              type="primary"
              icon="el-icon-plus"
              v-if="saveButton"
              @click="roleSave()"
              >保存
            </el-button>
          </div>
        </el-card>
      </div>
    </div>

    <el-dialog
      :title="
        dialogType === 1
          ? '新增角色'
          : dialogType === 2
          ? '查看角色'
          : '修改角色'
      "
      :visible.sync="dialogVisible"
      width="600px"
    >
      <el-form
        ref="roleForm"
        :model="roleForm"
        :rules="roleRules"
        label-width="80px"
      >
        <el-form-item label="角色名称" prop="name">
          <el-input
            type="text"
            v-model="roleForm.name"
            placeholder="请输入角色名称"
            :disabled="dialogType === 2"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色编码" prop="code">
          <el-input
            type="text"
            v-model="roleForm.code"
            placeholder="请输入角色编码"
            :disabled="dialogType === 2"
          ></el-input>
        </el-form-item>

        <el-form-item label="菜单" prop="menus">
          <div style="max-height: 300px; overflow: hidden; overflow-y: auto">
            <el-tree
              ref="menuTree"
              :data="menuTree"
              :props="treeProps"
              show-checkbox
              check-strictly
              default-expand-all
              node-key="id"
              :default-checked-keys="checkedKeys"
            >
            </el-tree>
          </div>
        </el-form-item>

        <el-form-item label="排序" prop="sortNum">
          <el-input-number
            v-model="roleForm.sortNum"
            :min="0"
            placeholder="请输入排序"
            :disabled="dialogType === 2"
          >
          </el-input-number>
        </el-form-item>
        <el-form-item label="是否禁用" prop="isDisabled">
          <el-switch
            v-model="roleForm.isDisabled"
            :active-value="1"
            :inactive-value="0"
            :disabled="dialogType === 2"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            v-model="roleForm.remark"
            placeholder="请输入备注"
            :disabled="dialogType === 2"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="dialogType !== 2"
          type="primary"
          @click="handleConfirm"
          :loading="confirmLoading"
          >确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryRole,
  addRole,
  getRole,
  updateRole,
  disableRole,
  enableRole,
  deleteRole,
  queryGroupRole,
  queryRolePermission,
} from "@/api/role";
import { queryPermissionDict, queryPageButton } from "@/api/permission";
import { listToTrees } from "@/utils/list-to-tree";

export default {
  name: "RoleMgt",
  data() {
    const validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("角色名称不能为空"));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("角色编码不能为空"));
      } else {
        callback();
      }
    };
    const validateIsDisabled = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("是否禁用不能为空"));
      } else {
        callback();
      }
    };
    return {
      changeIndex: 0,
      sortIsActive: 0,
      isplays: -1,
      isDelete: false,
      qyroleList: [],
      qxDictionary: [],
      rolePermissionFromList: {},
      chechRolePermissionNameList: [],
      equalChechRolePermissionNameList: [],
      updateId: "",
      searchName: "",
      roleList: [],
      tableLoading: false,
      tableSelection: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogType: 1,
      dialogVisible: false,
      roleForm: {},
      expandedKeys: [],
      checkedKeys: [],
      roleRules: {
        name: [{ required: true, trigger: "blur", validator: validateName }],
        code: [{ required: true, trigger: "blur", validator: validateCode }],
        isDisabled: [
          { required: true, trigger: "blur", validator: validateIsDisabled },
        ],
      },
      menuTree: [],
      treeProps: {
        label: "name",
        children: "children",
        disabled: "disabled",
      },
      checkedKeys: [],
      checked: [],
      confirmLoading: false,
      zxCheck: false,
      addButton: false,
      updateButton: false,
      deleteButton: false,
      saveButton: false,
    };
  },
  created() {
    this.queryButton();
    this.queryGroupRoles();
    this.queryMenu();
  },
  methods: {
    async queryGroupRoles() {
      const resp = await queryGroupRole();
      const datas = resp.data;
      let arrList = [];
      Object.keys(resp.data).map((values) => {
        if (values != "admin") {
          datas[values].map((tt) => {
            arrList.push(tt);
          });
        }
      });
      // console.info(arrList);
      this.qyroleList = arrList;
    },
    queryButton() {
      const data = 12;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "add") {
            this.addButton = true;
          }
          if (button.code === "delete") {
            this.deleteButton = true;
          }
          if (button.code === "save") {
            this.saveButton = true;
          }
          if (button.code === "update") {
            this.updateButton = true;
          }
        }
      });
    },
    query() {
      this.tableLoading = true;
      const data = {
        name: this.searchName,
      };

      queryRole(this.currentPage, this.pageSize, data).then((resp) => {
        this.roleList = resp.data.content;
        this.total = resp.data.totalElements;
        this.tableLoading = false;
      });
    },
    queryMenu() {
      queryPermissionDict().then((resp) => {
        const data = resp.data;
        if (this.dialogType === 2) {
          for (const item of data) {
            item.disabled = true;
          }
        }
        let menuTree = listToTrees(data);
        this.menuTree = menuTree.data;
        this.expandedKeys = menuTree.ext;
      });
    },
    handleSelectionChange(val) {
      this.tableSelection = val;
    },
    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    handleAdd() {
      this.roleForm = {};
      if (this.$refs.roleForm !== undefined) {
        this.$refs.roleForm.resetFields();
      }
      this.checkedKeys = [];
      if (this.$refs.menuTree !== undefined) {
        this.$refs.menuTree.setCheckedKeys([]);
      }
      this.dialogType = 1;
      this.queryMenu();
      this.dialogVisible = true;
    },
    handleConfirm() {
      if (this.dialogType === 1) {
        this.add();
      } else if (this.dialogType === 3) {
        this.update();
      }
    },
    add() {
      if (!this.roleForm.isDisabled) {
        this.roleForm.isDisabled = 0;
      }
      this.$refs.roleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const data = this.roleForm;
          const menus = [];
          const checkedKeys = this.$refs.menuTree.getCheckedKeys();
          if (!checkedKeys || checkedKeys.length === 0) {
            this.$alert("菜单不能为空", "提示", {
              confirmButtonText: "确定",
              callback: (action) => {},
            });
            this.confirmLoading = false;
            return;
          }
          for (const item of checkedKeys) {
            menus.push({
              id: item,
            });
          }
          data.menus = menus;

          addRole(data)
            .then((resp) => {
              this.$notify({
                title: "新增成功",
                message: resp.message,
                type: "success",
              });
              this.confirmLoading = false;
              this.dialogVisible = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {
              this.confirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleDisable() {
      if (this.isDelete === null || this.tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的角色不能为空",
          type: "warning",
        });
        return;
      }

      const data = [];
      for (const item of this.tableSelection) {
        data.push(item.id);
      }

      disableRole(data)
        .then((resp) => {
          this.$notify({
            title: "禁用成功",
            message: resp.message,
            type: "success",
          });
          this.currentPage = 1;
          this.query();
        })
        .catch((error) => {});
    },
    handleEnable() {
      if (this.tableSelection === null || this.tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的角色不能为空",
          type: "warning",
        });
        return;
      }

      const data = [];
      for (const item of this.tableSelection) {
        data.push(item.id);
      }

      enableRole(data)
        .then((resp) => {
          this.$notify({
            title: "启用成功",
            message: resp.message,
            type: "success",
          });
          this.currentPage = 1;
          this.query();
        })
        .catch((error) => {});
    },
    handleDelete() {
      if (!this.isDelete) {
        this.$notify({
          title: "警告",
          message: "选择的角色不能为空",
          type: "warning",
        });
        return;
      }

      this.$confirm("此操作将删除该角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = [];
          for (const item of this.tableSelection) {
            data.push(item.id);
          }

          deleteRole(data)
            .then((resp) => {
              this.$notify({
                title: "删除成功",
                message: resp.message,
                type: "success",
              });
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    handleView(id) {
      const data = id;
      getRole(data)
        .then((resp) => {
          if (this.$refs.roleForm !== undefined) {
            this.$refs.roleForm.resetFields();
          }
          const checkedKeys = [];
          for (const item of resp.data.menus) {
            checkedKeys.push(item.id);
          }
          this.checkedKeys = checkedKeys;
          if (this.$refs.menuTree !== undefined) {
            this.$refs.menuTree.setCheckedKeys(checkedKeys);
          }
          this.roleForm = resp.data;

          this.dialogType = 2;
          this.queryMenu();
          this.dialogVisible = true;
        })
        .catch((error) => {});
    },
    handleUpdate(id) {
      if (!this.rolePermissionFromList.menus) {
        this.$notify({
          title: "注意",
          message: "请选择一名角色",
          type: "warning",
        });
      } else {
        const data = id;
        getRole(data)
          .then((resp) => {
            if (this.$refs.roleForm !== undefined) {
              this.$refs.roleForm.resetFields();
            }
            const checkedKeys = [];
            for (const item of resp.data.menus) {
              checkedKeys.push(item.id);
            }
            this.checkedKeys = checkedKeys;
            if (this.$refs.menuTree !== undefined) {
              this.$refs.menuTree.setCheckedKeys(checkedKeys);
            }
            this.roleForm = resp.data;
            this.dialogType = 3;
            this.queryMenu();
            this.dialogVisible = true;
          })
          .catch((error) => {});
      }
    },
    update() {
      this.$refs.roleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const data = this.roleForm;
          const menus = [];
          const checkedKeys = this.$refs.menuTree.getCheckedKeys();
          if (!checkedKeys || checkedKeys.length === 0) {
            this.$alert("菜单不能为空", "提示", {
              confirmButtonText: "确定",
              callback: (action) => {},
            });
            this.confirmLoading = false;
            return;
          }
          for (const item of checkedKeys) {
            menus.push({
              id: item,
            });
          }
          data.menus = menus;
          updateRole(data)
            .then((resp) => {
              this.$notify({
                title: "修改成功",
                message: resp.message,
                type: "success",
              });
              this.confirmLoading = false;
              this.dialogVisible = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {
              this.confirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },

    async changes(index, id) {
      this.checked = [];
      this.chechRolePermissionNameList = [];
      this.isplays = index;
      this.updateId = id;
      this.changeIndex = index;
      this.isDelete = true;
      const resp = await queryRolePermission(id);
      if (resp.code === "0") {
        const respe = await getRole(id);
        this.rolePermissionFromList = respe.data;
        for (let index = 0; index < resp.data.length; index++) {
          this.chechRolePermissionNameList[index] = resp.data[index].id;
        }
        var repeatNumber = [];
        var repeatNumberList = [];
        for (let index = 0; index < resp.data.length; index++) {
          repeatNumber.push(resp.data[index].parentId);
        }
        repeatNumberList = Array.from(new Set(repeatNumber));
        for (let index = 0; index < repeatNumberList.length; index++) {
          this.chechRolePermissionNameList.push(repeatNumberList[index]);
        }
        // this.checkedKeys = []
        // this.checkedKeys = Array.from(new Set(this.chechRolePermissionNameList));
        this.checked = Array.from(new Set(this.chechRolePermissionNameList));
      } else {
        this.$message.error(resp.message);
      }
    },
    async Chess(parentIds, item, obj) {
      let ids = JSON.parse(JSON.stringify(this.checked));
      let isChecked = ids.filter((o) => o == obj.id);
      let parentIdNumber = [];
      parentIdNumber = parentIds.split(",");
      parentIdNumber.splice(parentIdNumber.length - 1, 1);
      let parentIdList = [];
      parentIdList = Array.from(new Set(parentIdNumber));
      for (let index = 0; index < parentIdList.length; index++) {
        ids.push(parseInt(parentIdList[index]));
      }

      if (!item) {
      } else {
        let childtIdList = [];
        childtIdList = item;
        if (isChecked.length) {
          for (let index = 0; index < childtIdList.length; index++) {
            ids.push(parseInt(childtIdList[index].id));
            if (
              childtIdList[index].children != undefined &&
              childtIdList[index].children.length
            ) {
              childtIdList[index].children.map((item) => {
                ids.push(parseInt(item.id));
                if (item.children != undefined && item.children.length) {
                  item.children.map((tf) => {
                    ids.push(tf.id);
                  });
                }
              });
            }
          }
        } else {
          let json = [];
          let children = [];
          //获取要移除的数据
          childtIdList.map((item) => {
            children.push(item.id);
            if (item.children != undefined && item.children.length) {
              item.children.map((tt) => {
                children.push(tt.id);
                if (tt.children != undefined && tt.children.length) {
                  tt.children.map((tf) => {
                    children.push(tf.id);
                  });
                }
              });
            }
          });
          ids.map((items) => {
            let child = children.filter((o) => o == items);
            if (!child.length) {
              json.push(items);
            }
          });
          ids = json;
        }
      }
      this.checked = Array.from(new Set(ids));
      // console.log(this.checked);
    },
    async roleSave() {
      if (!this.rolePermissionFromList.menus) {
        this.$notify({
          title: "注意",
          message: "请选择一名角色",
          type: "warning",
        });
      } else {
        const data = this.rolePermissionFromList;
        const menus = [];
        for (const item of this.checked) {
          menus.push({
            id: item,
          });
        }
        data.menus = menus;
        const resp = await updateRole(data);
        if (resp.code === "0") {
          this.$notify({
            title: "保存成功",
            message: "角色权限修改成功",
            type: "success",
          });
          this.checked = [];
        } else {
          this.confirmLoading = false;
        }
      }
    },
  },
};
</script>

<style>
.RT {
  margin-left: 10px;
}

.RT .el-button--primary {
  color: #fff;
  background-color: #ffffff;
  border-color: #446cf3;
  color: #446cf3;
}

.RE .el-button--primary {
  color: #fff;
  background-color: #446cf3;
  border-color: #446cf3;
}

.noSelect {
  margin-left: 25px;
  font-size: 14px;
  color: darkgrey;
}

.okSelect {
  margin-left: 25px;
  font-size: 15px;
  color: black;
  font-weight: bold;
}

.bnts {
  margin-left: 571px;
}

.FGs .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 16px;
}

.FGss .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 15px;
}

.FGss .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}

.FGs .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}

.FGsss .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}

.FGss {
  margin-top: 29px;
}

.FGsss {
  margin: 34px;
  display: flex;
  margin-top: 12px;
  flex-wrap: wrap;
}

.FGs {
  margin-top: 29px;
}

.TY .el-card__body {
  padding: 0px !important;
  margin-top: -1px;
}
</style>

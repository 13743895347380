import request from "@/utils/request";

export function queryRoleDict() {
  return request({
    url: "/sys/sysRole/queryRoleDict",
    method: "get",
  });
}

export function queryRole(page, size, data) {
  return request({
    url: "/sys/sysRole/queryRole?page=" + page + "&size=" + size,
    method: "post",
    data,
  });
}

export function addRole(data) {
  return request({
    url: "/sys/sysRole/addRole",
    method: "post",
    data,
  });
}

export function getRole(account) {
  return request({
    url: "/sys/sysRole/getRole/" + account,
    method: "get",
  });
}

export function updateRole(data) {
  return request({
    url: "/sys/sysRole/updateRole",
    method: "post",
    data,
  });
}

export function disableRole(data) {
  return request({
    url: "/sys/sysRole/disableRole",
    method: "post",
    data,
  });
}

export function enableRole(data) {
  return request({
    url: "/sys/sysRole/enableRole",
    method: "post",
    data,
  });
}

export function deleteRole(data) {
  return request({
    url: "/sys/sysRole/deleteRole",
    method: "post",
    data,
  });
}

export function queryGroupRole() {
  return request({
    url: "/sys/sysRole/queryGroupRole",
    method: "get",
  });
}

export function queryRolePermission(rid) {
  return request({
    url: "/sys/sysPermission/queryRolePermission/" + rid,
    method: "get",
  });
}
